import React, {useState, useContext, useEffect} from 'react';
import LocalizedLink from "../components/localizedLink"
import { CSSTransition } from 'react-transition-group';
import img2 from '../images/rainberry-decentralized.svg'
import img3 from '../images/rainberry-products.svg'
import logo from '../images/rainberry-logo.svg'
import mobileHero from '../images/rainberry-hero-img.svg'

const Index = (props) => {

  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    setShowAnimation(true)
  },[]);

  return (
    <>
      <div className="w-100 hero pb-5 pt-3 pt-md-5  text-left position-relative overflow-hidden">
        <div className="container pt-3">
          <div className="px-0 col-12 mb-5">
            <a href="/" className="d-block" id="hero-logo">
              <img src={logo} alt="Rainberry Logo" className="rainberry-logo"/>
            </a>
          </div>
          <div className="w-100 text-center">
            <img src={mobileHero} className="img-fluid w-100 hero-img" alt="Developers connected with millions of users."/>
          </div>
          <div className="px-0 col-12 col-lg-6 pt-3">
            <h1 className="mt-4 mt-lg-0 mb-4">Welcome to Rainberry</h1>
            <p>With almost 100 million people enjoying our decentralized software and entertainment focused VOD platform each month, Rainberry, Inc. (formerly known as “BitTorrent, Inc.“) is behind some of the world’s most popular applications.</p>
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column flex-lg-row my-5 py-5 text-left">
        <div className="px-0 col-12 col-lg-6 text-center">
          <img className="img-fluid" src={img2} alt="Multiple devices like computers, laptops, and mobile platforms connected in a decentralizzed manner."/>
        </div>
        <div className="px-0 col-12 offset-lg-1 col-lg-5 d-flex flex-column justify-content-center">
          <h2 className="mt-4 mt-lg-0 mb-5 mb-lg-4">Leader in decentralized technologies</h2>
          <p>Based in San Francisco, Rainberry is responsible for creating the largest decentralized communications protocol for distributing data and large files over the Internet.</p>
        </div>
      </div>

      <div className="container d-flex flex-column flex-lg-row my-5 py-5 text-left">
        <div className="px-0 col-12 offset-lg-1 col-lg-6 order-lg-2 text-center">
          <img className="img-fluid" src={img3} alt="Pictograph of product screenshots being shown across devices."/>
        </div>
        <div className="px-0 col-12 order-lg-1 col-lg-5 d-flex flex-column justify-content-center">
          <h2 className="mt-4 mt-lg-0 mb-5 mb-lg-4">Worldclass products</h2>
          <p>Rainberry currently develops products that span across both desktop and mobile platforms and are enjoyed by users in virtually every country around the globe.</p>
        </div>
      </div>
    </>
  )

}

export default Index
